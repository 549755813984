import { useState, useEffect, useMemo } from 'react'
import {
  EuiBasicTable,
  EuiSpacer,
  EuiHorizontalRule,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiButton,
  EuiPageTemplate,
  EuiFieldText,
  EuiButtonIcon,
  EuiCheckbox,
} from '@elastic/eui'
import { Link, useNavigate } from 'react-router-dom'

// import euitheme
import { useEuiTheme } from '@elastic/eui'
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'
import useDebounce from 'hooks/useDebounce'
import { fetchBasicInfo, fetchSheetsType, removeSheet } from './redux/allSheetsSlice'
import useLocalStorage from 'hooks/useLocalStorage'
import { Talent } from 'helpers/Talent'
import { css } from '@emotion/react'
import axios from 'axios'
import DangerModal from 'DangerModal'
import _ from 'lodash'
import date from 'date-and-time'
import { ReactComponent as MagicWand } from 'assets/magic-wand.svg'
import { FaMagnifyingGlass } from 'react-icons/fa6'
import { Slide, toast } from 'react-toastify'
import useSearchOnlyRedirect from 'hooks/useSearchOnlyRedirect'

const CustomIcon = ({ Icon, size = '22px' }) => {
  return <Icon style={{ width: size, height: size }} />
}

const filterByTitle = (sheet, titleSearchTerm) => {
  const search = titleSearchTerm.toLowerCase()
  const result = sheet?.title && sheet.title.toLowerCase().includes(search)
  return result
}

const getTablePage = (pageIndex, pageSize, filtersWillApply, allSheets, filteredSheets) => {
  const { entities, ordered_sheets } = allSheets

  // `ordered_sheets` is an array of IDs. if no filters apply, we can get the
  // appropriate slice from this array based on the `pageIndex` and `pageSize`

  let page_sheet_ids = ordered_sheets.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)

  // return the sheets in the order they are in the `ordered_sheets` array

  const sortedSheets = page_sheet_ids.map((id) => entities[id]).filter((s) => s)

  return {
    pageOfItems: filtersWillApply ? filteredSheets : sortedSheets,
    totalItemCount: filtersWillApply ? filteredSheets.length : allSheets.ordered_sheets.length,
  }
}

const SheetTable = ({ sheetType = 'talent', onlyShowMine, titleFilter }) => {
  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(50)
  const [showPerPageOptions, setShowPerPageOptions] = useState(true)

  const navigate = useNavigate()

  const onTableChange = ({ page }) => {
    if (page) {
      console.log(page)
      const { index: pageIndex, size: pageSize } = page
      setPageIndex(pageIndex)
      setPageSize(pageSize)
      window.scrollTo(0, 0)
    } else {
      console.log('No page')
    }
  }

  const dispatch = useDispatch()

  const debouncedSearchTerm = titleFilter

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [sheetForDeletion, setSheetForDeletion] = useState(null)

  const user = useSelector((state) => state.user)

  useEffect(() => {
    let action = {
      sheet_type: sheetType,
      page: pageIndex + 1,
      limit: pageSize,
      only_mine: onlyShowMine,
    }

    if (debouncedSearchTerm.trim().length > 2) {
      action.title_filter = debouncedSearchTerm
    } else {
      action.page = pageIndex + 1
      action.limit = pageSize
    }

    dispatch(fetchSheetsType(action))
  }, [dispatch, sheetType, pageIndex, pageSize, onlyShowMine, debouncedSearchTerm])

  useEffect(() => {
    if (debouncedSearchTerm.length > 2) {
      setPageIndex(0)
    }
  }, [debouncedSearchTerm])

  const deleteSheet = async (sheet_id) => {
    const confirm = true
    if (confirm) {
      const deleteResponse = await axios.post('/api/user/talent-sheets/delete', {
        sheet_id: sheet_id,
      })
      if (deleteResponse.data.success) {
        dispatch(removeSheet(sheet_id))
      }
    }
  }

  const influencers = useSelector((state) => state.influencers.entities)
  const socials = useSelector((state) => state.socials.entities)

  const columns = [
    {
      name: 'Title',
      align: 'left',
      truncateText: false,
      render: (sheet) => {
        return (
          <div className="fs-5">
            <Link className="text-black fw-bold" to={`/talent-sheet/${sheet.id}`}>
              {sheet.title}
            </Link>
          </div>
        )
      },
    },
    {
      name: 'Talent',
      align: 'start',
      truncateText: false,
      mobileOptions: {
        show: false,
      },
      render: (sheet) => {
        const influencer_ids = sheet.influencer_ids

        if (_.isEmpty(influencer_ids)) {
          return null
        }

        return (
          <EuiFlexGroup
            onClick={() => {
              navigate(`/talent-sheet/${sheet.id}`)
            }}
            style={{
              cursor: 'pointer',
            }}
            gutterSize="s"
          >
            {influencer_ids.slice(0, 3).map((id) => {
              const influencer = influencers[id]
              const talent = new Talent(influencer, socials)

              return (
                <EuiFlexItem
                  css={css`
                    border-radius: 10px;
                    overflow: hidden;
                  `}
                  grow={false}
                  key={id}
                >
                  <EuiImage
                    size={72}
                    hasShadow
                    allowFullScreen={false}
                    url={talent.getPhotoUrl()}
                    alt={talent.influencer.fullname}
                  />
                </EuiFlexItem>
              )
            })}
            {influencer_ids.length > 3 && (
              <EuiFlexItem grow={false}>
                <div>+{influencer_ids.length - 3} more</div>
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        )
      },
    },
    {
      // field: 'created_at',
      align: 'center',
      name: 'Created',
      render: (sheet) => {
        const { created_at } = sheet

        return (
          <EuiText
            onClick={() => {
              navigate(`/talent-sheet/${sheet.id}`)
            }}
            style={{
              cursor: 'pointer',
            }}
            size="s"
          >
            <p>
              <strong>{created_at ? date.format(new Date(created_at), 'MMM D YYYY') : ''}</strong>
            </p>
          </EuiText>
        )
      },
    },
    {
      // field: 'creator_nickname',
      align: 'center',
      name: 'Creator',
      render: (sheet) => {
        const { creator_nickname } = sheet
        return (
          <EuiText
            onClick={() => {
              navigate(`/talent-sheet/${sheet.id}`)
            }}
            style={{
              cursor: 'pointer',
            }}
            size="s"
          >
            <p>
              <strong>{creator_nickname}</strong>
            </p>
          </EuiText>
        )
      },
    },
    {
      name: 'Actions',
      align: 'center',
      truncateText: false,
      render: (sheet) => {
        return (
          <>
            <EuiButtonIcon
              className="action-button"
              onClick={() => {
                setSheetForDeletion(sheet.id)
                setIsDeleteModalVisible(true)
              }}
              iconType="trash"
              aria-label="Delete"
              data-test-subj={`action-delete-${sheet.id}`}
            />
            <EuiButtonIcon
              className="action-button ms-3"
              onClick={() => {
                navigator.clipboard
                  .writeText(
                    `${window.location.origin}/public/talent-sheet/roster-view/${sheet.id}`,
                  )
                  .then(() => {
                    toast.success('Link copied to clipboard!', {
                      position: 'top-center',
                      autoClose: 1000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      transition: Slide,
                    })
                  })
              }}
              iconType="link"
              color="primary"
              aria-label="Copy Link"
            />
          </>
        )
      },
    },
  ]

  const allSheets = useSelector((state) => state.allSheets)

  const filtersWillApply = onlyShowMine || debouncedSearchTerm?.length > 2

  let filteredSheets = useMemo(() => {
    const { entities, own_sheets, ordered_sheets } = allSheets

    let filtered_sheets = ordered_sheets.map((id) => entities[id]).filter((s) => s)

    // order by created_at
    filtered_sheets = _.orderBy(filtered_sheets, ['created_at'], ['desc'])

    if (onlyShowMine) {
      filtered_sheets = filtered_sheets.filter((s) => s.creator_user_id === user.account_id)
    }

    if (debouncedSearchTerm !== '' && debouncedSearchTerm.length > 2) {
      // @ts-ignore
      filtered_sheets = _.filter(entities, (sheet) => {
        if (!sheet) {
          return false
        }
        // @ts-ignore
        return filterByTitle(sheet, debouncedSearchTerm)
      })

      return filtered_sheets.filter((s) => s)
    }

    return filtered_sheets.filter((s) => s !== undefined)
  }, [allSheets, debouncedSearchTerm, onlyShowMine, user.account_id])

  const memoizedSheets = useMemo(() => {
    return getTablePage(pageIndex, pageSize, filtersWillApply, allSheets, filteredSheets)
  }, [pageIndex, pageSize, filtersWillApply, allSheets, filteredSheets])

  const { pageOfItems, totalItemCount } = memoizedSheets

  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount: totalItemCount || 50,
    pageSizeOptions: [50, 100],
    showPerPageOptions,
  }
  const resultsCount =
    pageSize === 0 ? (
      <strong>All</strong>
    ) : (
      <>
        <strong>
          {pageSize * pageIndex + 1}-{Math.min(pageSize * pageIndex + pageSize, totalItemCount)}
        </strong>{' '}
        of {totalItemCount}
      </>
    )

  return (
    <>
      <EuiSpacer size="xl" />
      <EuiText size="xs">
        Showing {resultsCount} <strong>Sheets</strong>
      </EuiText>
      <EuiSpacer size="s" />
      <EuiHorizontalRule margin="none" style={{ height: 2 }} />
      <EuiBasicTable
        items={pageOfItems}
        columns={columns}
        pagination={pagination}
        onChange={onTableChange}
      />
      <DangerModal
        isVisible={isDeleteModalVisible}
        title="Delete Sheet"
        warningMessage="Are you sure you want to delete this sheet?"
        onConfirm={() => {
          if (sheetForDeletion) {
            deleteSheet(sheetForDeletion)
          }
          setIsDeleteModalVisible(false)
        }}
        onCancel={() => {
          setIsDeleteModalVisible(false)
          setSheetForDeletion(null)
        }}
      />
    </>
  )
}

const NewTalentSheets = () => {
  const { euiTheme } = useEuiTheme()
  useSearchOnlyRedirect()

  const dispatch = useDispatch()
  const [sheetTitleFilterInput, setSheetTitleFilterInput] = useState('')

  const debouncedSearchTerm = useDebounce(sheetTitleFilterInput, 500)

  useEffect(() => {
    dispatch(fetchBasicInfo())
  }, [])

  const [onlyShowMine, setOnlyShowMine] = useLocalStorage('onlyShowMine', false)

  return (
    <EuiPageTemplate restrictWidth="90%">
      <EuiPageTemplate.Header
        style={{
          borderBottom: '0',
        }}
        pageTitle="Talent Sheets"
        rightSideItems={[
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-end">
              <Link to="/talent-sheet-create">
                <EuiButton
                  className="text-black"
                  color="text"
                  style={{ border: '1px dotted darkgrey' }}
                >
                  <CustomIcon Icon={MagicWand} className="me-2 text-black" />
                  <span
                    style={{
                      textDecoration: 'underline',
                    }}
                    className="text-link-blue"
                  >
                    Create New Sheet
                  </span>
                </EuiButton>
              </Link>
            </div>
            <div className="mt-3">
              <EuiCheckbox
                id="onlyShowMine"
                label="Only display sheets created by me"
                checked={onlyShowMine}
                onChange={(e) => {
                  setOnlyShowMine(e.target.checked)
                }}
              />
            </div>
          </div>,
        ]}
      />
      <div
        style={{
          margin: '10px 20px',
          backgroundColor: euiTheme.colors.lightestShade,
        }}
      >
        <EuiPageTemplate.Section
          style={{
            borderRadius: '10px',
          }}
        >
          <div className="d-flex justify-content-end">
            <EuiFlexItem grow={false}>
              <EuiFieldText
                placeholder="Search by name"
                value={sheetTitleFilterInput}
                onChange={(e) => {
                  setSheetTitleFilterInput(e.target.value)
                }}
                append={
                  <div className="d-flex justify-content-center align-items-center px-3">
                    <FaMagnifyingGlass />
                  </div>
                }
              />
            </EuiFlexItem>
          </div>
          <SheetTable titleFilter={debouncedSearchTerm} onlyShowMine={onlyShowMine} />
        </EuiPageTemplate.Section>
      </div>
    </EuiPageTemplate>
  )
}

export default NewTalentSheets
