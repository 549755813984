import { NavLink, Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Dropdown from 'react-bootstrap/Dropdown'
import { useState } from 'react'
import _ from 'lodash'
import axios from 'axios'
import routes from './routes'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import BulletPoint from 'components/BulletPoint'
import { disableNotification } from 'redux/conversationSlice'
import { IoMail, IoMailOutline } from 'react-icons/io5'

import { ReactComponent as MessagesIcon } from 'assets/message-text-circle.svg'

import { LuSettings } from 'react-icons/lu'

import config from './config'

import '@elastic/eui/dist/eui_theme_light.css'
import { EuiProvider, EuiText } from '@elastic/eui'

import {
  EuiHeader,
  EuiHeaderLinks,
  EuiHeaderSectionItem,
  EuiHeaderSectionItemButton,
  EuiAvatar,
  EuiPopover,
  EuiFlexGroup,
  EuiFlexItem,
  useGeneratedHtmlId,
  EuiSpacer,
  EuiLink,
} from '@elastic/eui'

const CustomIcon = ({ Icon, size = '22px', color }) => {
  return <Icon style={{ width: size, height: size, color }} />
}

const HeaderIcon = ({ Icon, size = '22px' }) => {
  return <Icon className="me-1" style={{ width: size, height: size }} />
}

const HeaderLink = ({ title, route, Icon }) => {
  const location = useLocation()
  return (
    <Link
      className={`d-flex align-items-center py-2 mx-2 custom-navbar-link ${
        location.pathname === route ? 'text-link-blue active' : 'text-eui-gray'
      }`}
      to={route}
      style={{
        fontSize: '14px',
        marginBottom: '-4px',
      }}
    >
      <span className="pb-1">
        <HeaderIcon Icon={Icon} />
        {title}
      </span>
    </Link>
  )
}

const HeaderUserMenu = () => {
  const user = useSelector((state) => state.user)

  const userPopoverId = useGeneratedHtmlId({ prefix: 'userPopover' })
  const [isOpen, setIsOpen] = useState(false)
  const onMenuButtonClick = () => {
    setIsOpen(!isOpen)
  }
  const closeMenu = () => {
    setIsOpen(false)
  }

  const signOut = () => {
    axios.get('/api/user/signout').then(({ data }) => {
      window.location.href = '/'
    })
  }

  const button = (
    <EuiHeaderSectionItemButton
      aria-controls={userPopoverId}
      aria-expanded={isOpen}
      aria-haspopup="true"
      aria-label="Account menu"
      onClick={onMenuButtonClick}
    >
      <EuiAvatar name={user.user_name || ''} size="s" />
    </EuiHeaderSectionItemButton>
  )
  return (
    <EuiPopover
      id={userPopoverId}
      repositionOnScroll
      button={button}
      isOpen={isOpen}
      anchorPosition="downRight"
      closePopover={closeMenu}
      panelPaddingSize="m"
    >
      <div>
        <EuiFlexGroup gutterSize="m" responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiAvatar name={user.user_name || ''} size="xl" />
          </EuiFlexItem>
          <EuiFlexItem className="">
            <EuiText>
              <p>{user.user_name}</p>
            </EuiText>
            <EuiSpacer size="m" />
            <div className="d-flex flex-column">
              <div className="mb-3">
                <Link to="/configure" onClick={() => closeMenu()}>
                  Settings
                </Link>
              </div>
              <div className="mb-3">
                <Link to="/contact-tpp" onClick={() => closeMenu()}>
                  Contact Us
                </Link>
              </div>
              {/* link to /statistics  */}
              <div className="mb-3">
                <Link to="/talent-sheets/statistics" onClick={() => closeMenu()}>
                  Statistics
                </Link>
              </div>
              <div className="mb-3">
                <Link to="/signout" className="">
                  Log out
                </Link>
              </div>
            </div>
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
    </EuiPopover>
  )
}

const PageHeader = () => {
  const user = useSelector((state) => state.user)

  const conversations = useSelector((state) => state.conversations)

  if (!user.authed || user.noTeam) {
    return null
  }

  const hasUnreadConversation = _.get(conversations, 'unread_conversations', []).length > 0

  const siteHeaderLogoUrl =
    'https://tpp-content.us-east-1.linodeobjects.com/logos/tpp-logo-horizontal-sm-lb.png'

  return (
    <EuiHeader
      sections={[
        {
          items: [
            <div style={{ maxWidth: '240px' }}>
              <Link to="/">
                <img
                  src={siteHeaderLogoUrl}
                  alt="Elastic"
                  style={{ width: '100%', height: 'auto' }}
                />
              </Link>
            </div>,
          ],
        },
        {
          items: [
            <EuiHeaderLinks aria-label="App navigation light theme example">
              {routes
                .filter((r) => r.hasNavlink)
                .map((route, i) => {
                  if (route.shouldShow && !route.shouldShow(user)) return null
                  return (
                    <HeaderLink
                      key={`route-${i}`}
                      route={route.route}
                      title={route.title}
                      Icon={route.Icon}
                    />
                  )
                })}
            </EuiHeaderLinks>,
          ],
        },
        {
          items: [
            <EuiHeaderSectionItem>
              <EuiHeaderSectionItemButton>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '40px',
                    height: '40px',
                    border: '2px solid #ccc',
                    borderRadius: '4px',
                  }}
                >
                  <Link to="/configure">
                    <LuSettings color="#656565" size={'28px'} />
                  </Link>
                </div>
              </EuiHeaderSectionItemButton>
            </EuiHeaderSectionItem>,
            <EuiHeaderSectionItem>
              <EuiHeaderSectionItemButton
              // aria-label="2 Notifications"
              // notification={'2'}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '40px',
                    height: '40px',
                    border: `2px solid ${
                      hasUnreadConversation ? config.colors['bright-blue'] : '#ccc'
                    }`,
                    borderRadius: '4px',
                  }}
                >
                  <Link to="/messages">
                    <CustomIcon
                      Icon={MessagesIcon}
                      size={'32px'}
                      color={hasUnreadConversation ? config.colors['bright-blue'] : '#656565'}
                    />
                  </Link>
                </div>
              </EuiHeaderSectionItemButton>
            </EuiHeaderSectionItem>,
            <EuiHeaderSectionItem>
              <HeaderUserMenu />
            </EuiHeaderSectionItem>,
          ],
        },
      ]}
    />
  )
}

const EuiApp = () => {
  return (
    <EuiProvider>
      <PageHeader />
    </EuiProvider>
  )
}

const RenderNavLink = ({ route, title }) => {
  const location = useLocation()

  const isActive = location.pathname === route

  return (
    <Nav.Link
      as={Link}
      className={`py-2 mx-2 ${isActive ? 'active' : ''} ${
        location.pathname === route ? 'text-bold' : 'text-white'
      }`}
      to={route}
    >
      {title}
    </Nav.Link>
  )
}

function TPPNavbar() {
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const conversations = useSelector((state) => state.conversations)

  const signOut = () => {
    axios.get('/api/user/signout').then(({ data }) => {
      window.location.href = '/'
    })
  }

  if (!user.authed || user.noTeam) {
    return null
  }

  const hasUnreadConversation = conversations.unread_conversations.length > 0
  const hasConversations = conversations.conversations.ids.length > 0

  const showNotification =
    conversations.unread_conversations.length > 0 && !conversations.user_acknowledged_notification

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg={_.get(user, 'teamSettings.navBarColor') || 'tpp-dark-blue'}
        variant="dark"
        className={`d-print-none`}
      >
        <div className="container-fluid py-0">
          <Nav className="me-auto">
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              {routes
                .filter((r) => r.hasNavlink)
                .map((route, i) => {
                  if (route.shouldShow && !route.shouldShow(user)) return null

                  return (
                    <RenderNavLink key={`route-${i}`} route={route.route} title={route.title} />
                  )
                })}
            </Navbar.Collapse>
          </Nav>
          {user.authed && (
            <Nav className="me-3">
              {hasConversations && (
                <Nav.Link as={NavLink} to={`/messages`}>
                  <span className="me-3 d-none d-md-block">
                    {hasUnreadConversation ? (
                      <IoMail color={config.colors['shine-gold']} size={30} />
                    ) : (
                      <IoMailOutline color={config.colors['shine-gold']} size={30} />
                    )}
                  </span>
                </Nav.Link>
              )}
              <Dropdown align={'end'}>
                <Dropdown.Toggle variant="tpp-blue" id="dropdown-basic" className="text-white">
                  {showNotification && <BulletPoint className="" color="red" />} More
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => navigate('/configure')}>Settings</Dropdown.Item>
                  <Dropdown.Item onClick={() => navigate('/add-talent')}>Add Talent</Dropdown.Item>
                  <Dropdown.Item onClick={() => navigate('/contact-tpp')}>
                    Contact Us
                  </Dropdown.Item>
                  {conversations.conversations.ids.length > 0 && (
                    <Dropdown.Item
                      onClick={() => {
                        dispatch(disableNotification())
                        navigate('/messages')
                      }}
                    >
                      Messages {showNotification && <BulletPoint className="" color="red" />}
                    </Dropdown.Item>
                  )}

                  <Dropdown.Item onClick={() => navigate('/talent-sheets/statistics')}>
                    Statistics
                  </Dropdown.Item>
                  <Dropdown.Item onClick={signOut} href="#">
                    Sign Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          )}
        </div>
      </Navbar>

      {/* <hr /> */}
    </>
  )
}

export default PageHeader
