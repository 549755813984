import { createSlice } from '@reduxjs/toolkit'

import _ from 'lodash'

export const modalsSlice = createSlice({
  name: 'modals',
  initialState: {
    contactInfo: false,
    profileSidebar: {
      influencer_id: null,
    },
  },
  reducers: {
    setModalShow: (state, { payload }) => {
      state[payload.modal] = payload.show
    },
    setProfileSidebar: (state, { payload }) => {
      console.log('existing', state.profileSidebar.influencer_id, payload.influencer_id)
      if (!payload.influencer_id || payload.influencer_id === state.profileSidebar.influencer_id) {
        state.profileSidebar = {
          influencer_id: null,
        }
      } else {
        state.profileSidebar = {
          influencer_id: payload.influencer_id,
        }
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const { setModalShow, setProfileSidebar } = modalsSlice.actions

export default modalsSlice.reducer
