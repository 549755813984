import React, { useState, useEffect, useMemo } from 'react'
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'
import useLocalStorage from 'hooks/useLocalStorage'
import _, { create } from 'lodash'
import {
  fetchOneUserSheet,
  setNameFilter,
  saveUserSheet,
  debouncedSaveUserSheet,
  setTitle,
} from 'redux/userSheetSlice'
import { useParams } from 'react-router'
import 'react-grid-layout/css/styles.css'

import {
  EuiPageTemplate,
  EuiPageTemplateProps,
  EuiPageSidebarProps,
  EuiButton,
  EuiFieldText,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  useGeneratedHtmlId,
  EuiFormRow,
  EuiCheckbox,
  EuiToolTip,
} from '@elastic/eui'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

import { useEuiTheme } from '@elastic/eui'

const NewNewSheetModal = ({
  panelled,
  offset,
  grow,
  sheetType = 'talent',
}: {
  panelled?: EuiPageTemplateProps['panelled']
  // For fullscreen only
  sidebarSticky?: EuiPageSidebarProps['sticky']
  offset?: EuiPageTemplateProps['offset']
  grow?: EuiPageTemplateProps['grow']
  sheetType?: string
}) => {
  const dispatch = useDispatch()
  const params = useParams()
  const [editTitle, setEditTitle] = useState(false)

  const talentSheet = useSelector((state) => state.userSheet)

  const isNewTalentSheet = params.talentSheetId === 'new-talent-sheet'

  const modalTitleId = useGeneratedHtmlId()

  const [modalSheetTitle, setModalSheetTitle] = useState('')
  const [usePublicTitle, setUsePublicTitle] = useState(false)

  const navigate = useNavigate()

  const createNewSheet = async () => {
    const data = {
      title: modalSheetTitle,
      description: usePublicTitle ? modalSheetTitle : undefined,
      sheet_type: 'talent',
      influencers: [],
    }

    try {
      const response = await axios.post('/api/user/talent-sheets/save', data)
      if (response.data.sheet_id) {
        navigate(`/talent-sheet/${response.data.sheet_id}`)
      }
    } catch (e) {
      // @ts-ignore
      const error = _.get(e, 'response.data.error')
      throw new Error(error)
    }
  }

  return (
    <EuiPageTemplate panelled={panelled} offset={offset} grow={grow}>
      <>
        <EuiModal
          aria-labelledby={modalTitleId}
          onClose={() => {
            navigate('/talent-sheets')
          }}
        >
          <EuiModalHeader>
            <EuiModalHeaderTitle id={modalTitleId}>Title for the New Sheet</EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            <EuiFormRow label="Sheet Name">
              <EuiFieldText
                name="sheet_name"
                value={modalSheetTitle}
                onChange={(e) => setModalSheetTitle(e.target.value)}
              />
            </EuiFormRow>
            <EuiFormRow>
              <EuiToolTip content='If not checked, the title will display as "Selected Talent" when visiting the public link'>
                <EuiCheckbox
                  id="usePublicTitle"
                  label="Display above title when sharing"
                  checked={usePublicTitle}
                  onChange={(e) => setUsePublicTitle(e.target.checked)}
                />
              </EuiToolTip>
            </EuiFormRow>
          </EuiModalBody>

          <EuiModalFooter>
            <EuiButton
              onClick={() => {
                createNewSheet()
              }}
              fill
            >
              Create
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      </>
    </EuiPageTemplate>
  )
}

export default NewNewSheetModal
