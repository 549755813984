import { useEffect, useState, useMemo } from 'react'
import { EuiTitle } from '@elastic/eui'

import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { fetchSingleInfluencer } from 'redux/influencerSlice'
import { Talent } from 'helpers/Talent'
import RenderMarkdown from 'components/RenderMarkdown'
import 'react-circular-progressbar/dist/styles.css'
import 'components/InternalProfile.scss'
import GeoVisitBox from 'components/NewProfile/CountryVisitBox'
import AudienceGenderStats from 'components/NewProfile/AudienceGenderStats'
import SocialMediaStats from 'components/NewProfile/SocialMediaStats'
import { FaTimes } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { setProfileSidebar } from 'redux/modalsSlice'

const NewSidebarProfile = ({ influencer_id }) => {
  const dispatch = useDispatch()

  const setSelectedPerson = (id) => {
    dispatch(setProfileSidebar({ influencer_id: id }))
  }
  // const selectedPerson = useSelector((state) => state.modals.profileSidebar.influencer_id)

  const [selectedSocial, setSelectedSocial] = useState(null)

  const handleSwitchPlatform = (id) => {
    setSelectedSocial(id)
  }

  const influencer = useSelector((state) => state.influencers.entities[influencer_id])
  const socials = useSelector((state) => state.socials.entities)

  const talent = influencer ? new Talent(influencer, socials) : null

  const allSocials = talent ? talent.getSocials().allSocials : []

  useEffect(() => {
    dispatch(fetchSingleInfluencer({ influencer_id }))
  }, [dispatch, influencer_id])

  useEffect(() => {
    if (allSocials.length > 0) {
      // set the selected social to the first social in the list
      setSelectedSocial(allSocials[0].id)
    }
  }, [influencer, influencer_id])

  if (!influencer || !influencer?.with_advanced_data) {
    return <div>Loading...</div>
  }

  return (
    <div className="container bg-white card py-2">
      <div className="d-flex justify-content-end">
        <button onClick={() => setSelectedPerson(null)}>
          <FaTimes />
        </button>
      </div>
      <div className="d-flex my-3">
        <div className="">
          <img
            style={{
              width: '100%', // fit the width of the column
              borderRadius: '5%', // slightly rounded corners
              height: '75px',
              width: '75px',
            }}
            src={talent.getPhotoUrl()}
            alt="Profile"
          />
        </div>
        <div className="ms-2 mb-2 align-content-center d-flex flex-column ">
          <span className="fw-bold fs-5">{influencer.fullname}</span>
          <div>
            <Link
              className="text-black text-decoration-underline"
              to={`/influencers/profile/${influencer.id}`}
            >
              Edit Profile
            </Link>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column">
        <div className="d-flex">
          <SocialMediaStats
            withText={false}
            talent={talent}
            selectedSocial={selectedSocial}
            setSelectedSocial={handleSwitchPlatform}
          />
        </div>
        <div className="d-flex mt-2">
          <AudienceGenderStats
            talent={talent}
            selectedSocial={selectedSocial}
            setSelectedSocial={handleSwitchPlatform}
          />
        </div>
        <div className="d-flex mt-2">
          <GeoVisitBox selectedSocial={selectedSocial} setSelectedSocial={handleSwitchPlatform} />
        </div>
      </div>
    </div>
  )
}

export default NewSidebarProfile
