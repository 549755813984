import React, { Fragment, useEffect, useState, useRef, useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import { fetchCollection, fetchPublicCollection } from './redux/collectionsSlice'
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'

import _ from 'lodash'
import { sendEvent } from 'analytics'
import { getContrastColor, joinClassNames, getContrastingTextForBackground } from 'utils'
import Color from 'color'
import config from './config'
import ContactInfoModal from 'components/ContactInfoModal'
import { setModalShow } from 'redux/modalsSlice'
import { getColorHex } from 'utils'

const getBackgroundTextContrast = (backgroundColor) => {
  backgroundColor = config.colors[backgroundColor] || backgroundColor

  const rgbBgColor = Color(backgroundColor).rgb().string()
  const contrastColor = getContrastColor(rgbBgColor)
  return `text-${contrastColor}`
}

function CollectionHeader({ collection, displaySettings }) {
  const dispatch = useDispatch()

  const titleFont = displaySettings?.displayRosterTitleFont || 'neuton'

  const backgroundColor = displaySettings?.displayProfileBackground
    ? displaySettings.displayRosterBackgroundColor
    : 'white'

  const textColorClass = `text-${getContrastingTextForBackground(backgroundColor)}`

  const hasContactInfo =
    !_.isEmpty(collection?.team_contact_email) ||
    !_.isEmpty(collection?.team_phone) ||
    !_.isEmpty(collection?.team_homepage)

  const activateContactModal = () => {
    if (!hasContactInfo) return
    dispatch(setModalShow({ modal: 'contactInfo', show: true }))
  }

  const darkMode = displaySettings.displayDarkMode

  const backgroundColorHex = useMemo(() => {
    if (!displaySettings?.displayProfileBackground) return getColorHex('white')
    return getColorHex(displaySettings.displayRosterBackgroundColor)
  })

  const styles = {
    backgroundColor: backgroundColorHex,
  }

  return (
    <div
      className={joinClassNames([
        `container-fluid`,
        `bottom-drop-shadow`,
        darkMode ? 'dark-mode' : null,
      ])}
      style={styles}
    >
      <div className="row align-items-center py-2">
        <div className="col-12 col-md-4">
          {collection.team_image_url ? (
            <div className="team-logo-box d-flex justify-content-center justify-content-md-start px-3">
              <div
                onClick={activateContactModal}
                className={joinClassNames([
                  'team-logo-container',
                  hasContactInfo ? 'selectable' : null,
                ])}
              >
                <img className="" src={collection.team_image_url} />
                {hasContactInfo && (
                  <div className="d-flex flex-column justify-content-center">
                    <button className="btn btn-link" onClick={activateContactModal}>
                      Contact
                    </button>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="team-name-box d-flex flex-column justify-content-center pb-3 me-2">
              <div className="text-bold ps-3">{collection.team_name}</div>
            </div>
          )}
        </div>
        <div className="col-12 col-md-4">
          <h2
            className={joinClassNames([
              `sheet-header-text text-center font-${titleFont} p-2`,
              textColorClass,
            ])}
          >
            {collection.title}
          </h2>
        </div>
      </div>
    </div>
  )
}

function CollectionTalentSheetItem({ sheet, displaySettings = {} }) {
  // const influencers = useSelector((state) => state.influencers.entities)
  // const socials = useSelector((state) => state.socials.entities)
  const influencerBasicInfo = useSelector((state) => state.collections.influencersBasicInfo)

  const photos = sheet.influencer_ids.map((id) => {
    const influencer = influencerBasicInfo[id]
    if (!influencer) {
      return null
    }
    const photo = influencer.custom_picture || influencer.picture_main_url
    return photo
  })

  const titleFont = displaySettings.displayRosterTitleFont || 'neuton'

  return (
    <div
      data-sheet-id={sheet.id}
      className="col-xxl-4 col-md-6 public-team-roster-influencer-holder collection-sheet-holder my-3"
    >
      <div
        style={{ height: '100%' }}
        className="d-flex flex-column p-3 roster-box bg-white rounded shadow"
      >
        <div className="roster-name text-center py-2 mb-3">
          <Link
            style={{ textDecoration: 'none' }}
            className="text-dark"
            to={`/public/talent-sheet/roster-view/${sheet.id}`}
          >
            <h2 className={`font-${titleFont}`}>{sheet.description}</h2>
          </Link>
        </div>
        <div className="d-flex flex-column">
          <div className="selectable portrait-rounded text-center mb-3">
            <div className="">
              <Link to={`/public/talent-sheet/roster-view/${sheet.id}`}>
                <div className="d-flex justify-content-center collection-photo-holder">
                  <div className="row w-75">
                    <div className="d-flex flex-row justify-content-center">
                      {photos.slice(0, 2).map((photo, i) => {
                        return (
                          <div key={`photo-${i}`} className="col-6 px-2">
                            <div className="portrait-rounded text-center">
                              <div className="">{photo && <img src={photo} />}</div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                    <div className="d-flex flex-row justify-content-center">
                      {photos.slice(2, 3).map((photo, i) => {
                        return (
                          <div key={`photo-${i}`} className="col-6 px-2">
                            <div className="portrait-rounded text-center">
                              <div className="">{photo && <img src={photo} />}</div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default function PublicCollection() {
  const dispatch = useDispatch()
  const params = useParams()
  const basicTalentSheets = useSelector((state) => state.collections.sheetBasicInfo)

  const showContactInfo = useSelector((state) => {
    return state.modals.contactInfo === true
  })

  const collection = useSelector((state) => state.collections.collections[params.collectionId])

  const team = useSelector((state) => state.team)

  const displaySettings = useSelector((state) => {
    const collection = state.collections.collections[params.collectionId]
    if (collection && collection.displaySettings) return collection.displaySettings
    return team.settings
  })

  const backgroundColorHex = useMemo(() => {
    if (!displaySettings?.displayProfileBackground) return getColorHex('white')
    return getColorHex(displaySettings.displayRosterBackgroundColor)
  })

  useEffect(() => {
    sendEvent('collection_view', {
      collection_id: params.collectionId,
    })
    dispatch(fetchCollection({ collection_id: params.collectionId, publicAccess: true }))
  }, [dispatch, params.talentSheetId])

  const mainContainerRef = useRef(null)

  const backgroundColor = displaySettings.displayRosterBackgroundColor || 'white'

  const textColorClass = getBackgroundTextContrast(backgroundColor)

  if (!collection) {
    return <div>Loading...</div>
  }

  const collectionSheets = _.map(collection.salesheet_ids, (id) => {
    return basicTalentSheets[id]
  }).filter((s) => s)
  if (!collection || _.isEmpty(collectionSheets)) {
    return <div>Loading...</div>
  }

  const styles = {
    backgroundColor: backgroundColorHex,
  }

  return (
    <>
      <CollectionHeader collection={collection} displaySettings={displaySettings} />
      <div
        ref={mainContainerRef}
        className={`bg-${backgroundColor}`}
        style={{ minHeight: '85vh', ...styles }}
      >
        <div className="container">
          <div className="row pt-3">
            <div className="col-12 text-center">
              <p
                className={joinClassNames(['text-center', textColorClass])}
                style={{ fontSize: '1.4em' }}
              >
                (click any square for expanded roster)
              </p>
            </div>
          </div>
          <div className="row">
            {collection &&
              _.map(collectionSheets, (sheet) => (
                <Fragment key={`sheet-${sheet.id}`}>
                  <CollectionTalentSheetItem sheet={sheet} displaySettings={displaySettings} />
                </Fragment>
              ))}
          </div>
          <ContactInfoModal
            showModal={showContactInfo}
            handleClose={() => dispatch(setModalShow({ modal: 'contactInfo', show: false }))}
            logoUrl={collection?.team_image_url}
            email={collection?.team_contact_email}
            phoneNumber={collection?.team_phone}
            homepage={collection?.team_homepage}
            bio={collection?.team_bio}
          />
        </div>
      </div>
    </>
  )
}
