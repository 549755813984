import { useSelector } from 'react-redux'
import PublicTalentRoster from 'PublicTalentRoster'
import NewPublicTalentRoster from 'NewPublicTalentRoster'
import _ from 'lodash'

export default function PublicRosterController() {
  const team = useSelector((state) => state.team)
  console.log(team)
  const teamSettings = useSelector((state) => {
    return team.settings
  })

  return teamSettings.displayOldRoster ? <PublicTalentRoster /> : <NewPublicTalentRoster />
}
