import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { savePublicSheetHeading, setDescription } from 'redux/userSheetSlice'
import _ from 'lodash'

function CustomTitleModal({ show, onConfirm, onClose }) {
  const talentSheet = useSelector((state) => state.userSheet)

  const [titleInput, setTitleInput] = useState(talentSheet.description || '')

  const dispatch = useDispatch()

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{'Set Public Title'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p style={{ fontSize: '1.2em' }}>
                Currently displaying:{' '}
                <span className="fw-bold">
                  {_.isEmpty(talentSheet.description)
                    ? 'Selected Talent'
                    : talentSheet.description}
                </span>
              </p>
            </div>
          </div>
          {/* <div className="row mt-2">
            <div className="col-12">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={talentSheet.usePublicTitle}
                  id="displayTitleCheck"
                  onChange={(e) => {
                    if (!e.target.checked) {
                      dispatch(setDescription(null))
                      savePublicSheetHeading(null, params.talentSheetId).then(() => {
                        showSavedToast({ customText: 'Public Title Removed' })
                      })
                    } else {
                      dispatch(setDescription(''))
                    }
                  }}
                />
                <label
                  style={{ fontSize: '1.2em' }}
                  className="form-check-label"
                  htmlFor="displayTitleCheck"
                >
                  Replace default heading (instead of "Selected Talent")
                </label>
              </div>
            </div>
          </div> */}
          <form
            onSubmit={(e) => {
              e.preventDefault()
              savePublicSheetHeading(titleInput, talentSheet.id).then((res) => {
                dispatch(setDescription(titleInput))
                // if (res.data.success) {
                //   showSavedToast({ customText: 'Public Title Saved' })
                // }
              })
            }}
          >
            <div className="d-flex flex-row">
              <div>
                <input
                  id="sheet-public-title-input"
                  className="form-control my-3"
                  type="text"
                  value={titleInput}
                  placeholder={'Custom Title'}
                  onChange={(e) => {
                    setTitleInput(e.target.value)
                    // dispatch(setDescription(e.target.value))
                  }}
                />
              </div>

              <div className="d-flex py-3 ms-2">
                <input type="submit" className="btn btn-bright-blue" value="Save" />
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          Confirm
        </Button>
      </Modal.Footer> */}
    </Modal>
  )
}

export default CustomTitleModal
