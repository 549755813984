import React from 'react'
import './Tag.scss'
import { joinClassNames } from 'utils'

const SpecialTag = ({
  name,
  color = '#dfeffd',
  textColor = '#333',
  backgroundColor = 'transparent',
  variant = 'filled',
  performRemove,
  className,
  onClick,
}) => {
  return (
    <div onClick={onClick} className={joinClassNames(['tag', variant, className])}>
      <div
        className="tag-name"
        style={{
          backgroundColor: backgroundColor,
          color: textColor,
        }}
      >
        {name}{' '}
        {performRemove && (
          <span onClick={performRemove} className="remove">
            &times;
          </span>
        )}
      </div>
      <div className="tag-triangle" style={{ borderLeftColor: backgroundColor }}></div>
    </div>
  )
}

export default SpecialTag
