import { useMemo } from 'react'
import { EuiFlexGroup, EuiFlexItem, EuiText, EuiTitle, EuiSpacer } from '@elastic/eui'

import { safelyMillify } from 'utils'
import { useSelector } from 'react-redux'
import 'react-circular-progressbar/dist/styles.css'
import DisplayChange from './DisplayChange'

const NumberDisplayBox = ({
  selectedSocial,
  setSelectedSocial,
  title,
  dataKey,
  numberDisplay,
  change,
}) => {
  return (
    <div className="card p-4 shadow-sm flex-fill">
      <div className="d-flex flex-row">
        <EuiTitle size="xs">
          <h3 className="text-center">{title}</h3>
        </EuiTitle>
      </div>
      <EuiSpacer size="m" />
      <div className="d-flex align-content-center">
        <div className="text-center fs-3">{numberDisplay}</div>
        {change ? (
          <div className="p-2">
            <DisplayChange change={change} />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default NumberDisplayBox
