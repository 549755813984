import { useState, useEffect } from 'react'
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'
import 'react-grid-layout/css/styles.css'

import { fetchCastingCalls } from 'redux/castingCallsSlice'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiPageTemplate,
  EuiButton,
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
} from '@elastic/eui'

import { useNavigate } from 'react-router-dom'

import CastingCallDisplay from 'components/CastingCallDisplay'
import date from 'date-and-time'

const CardComponent = ({ castingCall, logo, category, title, description, end_date }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const closeModal = () => setIsModalVisible(false)
  const showModal = () => setIsModalVisible(true)

  let modal

  if (isModalVisible) {
    modal = (
      <EuiOverlayMask>
        <EuiModal onClose={closeModal}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>{title}</EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            <CastingCallDisplay
              {...castingCall}
              responseUrl={`/creator-call-response/${castingCall.id}`}
            />
          </EuiModalBody>
        </EuiModal>
      </EuiOverlayMask>
    )
  }

  return (
    <div className="col-md-4 my-2">
      <div style={{ height: '100%' }} className="card mb-4 shadow-sm">
        <div className="card-body">
          <img src={logo} alt={title} className="img-fluid mb-2" style={{ height: '50px' }} />
          <h5 className="card-title d-flex justify-content-between align-items-center">
            {title}
            {/* <span className="badge bg-primary">{category}</span> */}
          </h5>
          <p className="card-text">{description}</p>
          <div className="d-flex justify-content-between align-items-center">
            <small className="text-muted">
              <i className="bi bi-calendar-event"></i>{' '}
              {date.format(new Date(end_date), 'MMM DD YYYY')}
            </small>
            <EuiButton onClick={showModal} type="button" className="mt-3">
              More Details
            </EuiButton>
            {modal}
          </div>
        </div>
      </div>
    </div>
  )
}

const RenderCalls = ({ calls }) => {
  const cardContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'stretch',
  }

  return (
    <>
      {calls.map((castingCall, i) => {
        return (
          <CardComponent
            key={`cc-${castingCall.id}`}
            // responseUrl={`/creator-call-response/${castingCall.id}`}
            // {...castingCall}
            castingCall={castingCall}
            logo={castingCall.team_image_url}
            title={castingCall.title}
            category={'Fun'}
            description={castingCall.description}
            end_date={castingCall.end_date}
          />
        )
      })}
    </>
  )
}

const NewCreatorCalls = ({ panelled, offset, grow }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [selectedCreatorCall, setSelectedCreatorCall] = useState(null)

  const [showHiddenCalls, setShowHiddenCalls] = useState(false)
  const userHiddenCalls = useSelector((state) => state.castingCalls.userHiddenCalls)

  const [showResponded, setShowResponded] = useState(false)

  const castingCalls = useSelector((state) => {
    return state.castingCalls.castingCalls.filter((call) => {
      return !userHiddenCalls.includes(call.id)
    })
  })

  useEffect(() => {
    dispatch(fetchCastingCalls())
  }, [])

  const unRespondedCalls = castingCalls.filter((castingCall) => {
    return !castingCall.response_sheet_id || castingCall.response_status === 'created'
  })

  const respondedCalls = castingCalls.filter((castingCall) => {
    return castingCall.response_sheet_id && castingCall.response_status === 'submitted'
  })

  const hiddenCalls = useSelector((state) => {
    return state.castingCalls.castingCalls.filter((call) => {
      return userHiddenCalls.includes(call.id)
    })
  })

  return (
    <EuiPageTemplate panelled={panelled} offset={offset} grow={grow}>
      <EuiFlexGroup>
        <EuiFlexItem grow={1}>
          <EuiPanel paddingSize="l" color="subdued" className="py-0">
            <div className="container-fluid">
              <div className="row">
                <div className="col-8">
                  <div className="d-flex flex-row py-3">
                    <>
                      <div
                        style={{
                          fontSize: '2.5rem',
                        }}
                        className="fw-bold"
                      >
                        {'Creator Calls'}
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>
            <EuiPanel paddingSize="m">
              <div className="container-fluid my-3">
                <div className="row">
                  <RenderCalls
                    setSelectedCreatorCall={setSelectedCreatorCall}
                    calls={castingCalls}
                  />
                </div>
              </div>
            </EuiPanel>
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPageTemplate>
  )
}

export default NewCreatorCalls
