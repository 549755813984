import React, { useState, useEffect, useMemo } from 'react'
import {
  formatDate,
  EuiBasicTable,
  EuiSpacer,
  EuiHorizontalRule,
  EuiText,
  EuiFlexItem,
  EuiButton,
  EuiPageTemplate,
  EuiFieldText,
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalFooter,
  EuiButtonEmpty,
} from '@elastic/eui'
import { Link, useNavigate } from 'react-router-dom'

import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'
import { css } from '@emotion/react'
import axios from 'axios'
import DangerModal from 'DangerModal'
import _ from 'lodash'
import { fetchAllCollections, removeCollection } from './redux/collectionsSlice'
import { CiCalendar } from 'react-icons/ci'
import { FaMagnifyingGlass } from 'react-icons/fa6'

import { ReactComponent as MagicWand } from 'assets/magic-wand.svg'

const CustomIcon = ({ Icon, size = '22px' }) => {
  return <Icon style={{ width: size, height: size }} />
}

const CollectionTable = ({ sheetType = 'talent' }) => {
  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(25)
  const [showPerPageOptions, setShowPerPageOptions] = useState(true)

  let collections = useSelector((state) => {
    const savedCollections = _.omit(state.collections.collections, 'new-collection')
    const result = _.orderBy(savedCollections, ['created_at'], ['desc'])
    return result
  })

  const collectionSheets = useSelector((state) => state.collections.sheets)

  const onTableChange = ({ page }) => {
    if (page) {
      const { index: pageIndex, size: pageSize } = page
      setPageIndex(pageIndex)
      setPageSize(pageSize)
    }
  }

  const dispatch = useDispatch()

  const [sheetTitleFilterInput, setSheetTitleFilterInput] = useState('')

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [collectionForDeletion, setCollectionForDeletion] = useState(null)

  const deleteCollection = async (collection_id) => {
    // setShow(false)
    console.log('deleteSheet', collection_id)

    const confirm = true
    if (confirm) {
      const deleteResponse = await axios.post('/api/user/collections/delete', {
        collection_id,
      })
      if (deleteResponse.data.success) {
        dispatch(removeCollection({ collection_id }))
      }
    }
    // setSheetToDelete(null)

    // deleteUsersByIds(sheet.id)
    // setSelectedItems([])
  }

  const actions = useMemo(() => {
    let actions = [
      {
        name: (collection) => (collection.id ? 'Delete' : 'Remove'),
        description: ({ title }) => `Delete`,
        icon: 'trash',
        color: 'danger',
        type: 'icon',
        onClick: (collection) => {
          console.log('delete', collection)
          setIsDeleteModalVisible(true)
          setCollectionForDeletion(collection.id)
        },
        isPrimary: true,
        'data-test-subj': ({ id }) => `action-delete-${id}`,
      },
      {
        name: 'Shareable Link',
        description: 'Shareable Link',
        icon: 'link',
        color: 'primary',
        type: 'icon',
        onClick: (collection) => {
          navigator.clipboard.writeText(
            `${window.location.origin}/public/collection/${collection.id}`,
          )
        },
        isPrimary: true,
      },
    ]
    return actions
  }, [])

  const columns = [
    {
      name: 'Title',
      align: 'left',
      truncateText: false,
      render: (collection) => {
        return (
          <div className="py-3">
            <Link
              to={`/collection/${collection.id}`}
              className="text-decoration-none fw-bold fs-4 text-dark"
            >
              {collection.title}
            </Link>
          </div>
        )
      },
      // mobileOptions: {
      //   render: (collection) => {
      //     return (
      //       <div>
      //         <Link to={`/sheets/${collection.id}`}>{collection.title}</Link>
      //       </div>
      //     )
      //   },
      //   header: false,
      //   truncateText: false,
      //   enlarge: true,
      //   width: '100%',
      // },
    },
    {
      field: 'salesheet_ids',
      name: 'Included Sheets',
      align: 'center',
      truncateText: false,
      mobileOptions: {
        show: false,
      },
      width: '45%',
      render: (salesheet_ids) => {
        return (
          <div className="d-flex justify-content-center">
            {salesheet_ids.slice(0, 3).map((id, index) => {
              const sheet = _.find(collectionSheets, { id: id })

              return (
                <React.Fragment key={id}>
                  {index > 0 && <EuiFlexItem grow={false}>•</EuiFlexItem>}
                  <EuiFlexItem
                    css={css`
                      border-radius: 10px;
                      overflow: hidden;
                      margin: 0 8px;
                    `}
                    grow={false}
                  >
                    {sheet?.title || 'No title'}
                  </EuiFlexItem>
                </React.Fragment>
              )
            })}
          </div>
        )
      },
    },
    {
      field: 'created_at',
      align: 'center',
      name: 'Created',
      render: (created_at) => {
        return (
          <EuiText size="s">
            <p>
              <CiCalendar className="me-2" color="#26aefb" />
              <strong>{formatDate(created_at, 'MMM d yyyy')}</strong>
            </p>
          </EuiText>
        )
      },
    },
    {
      name: '',
      align: 'center',
      actions,
    },
  ]

  let displayCollections = useMemo(() => {
    if (!sheetTitleFilterInput || sheetTitleFilterInput < 3) {
      return collections
    }

    const filteredCollections = collections.filter((collection) => {
      return collection.title.toLowerCase().includes(sheetTitleFilterInput.toLowerCase())
    })

    return filteredCollections
  }, [collections, sheetTitleFilterInput])

  const findCollections = (collections, pageIndex, pageSize) => {
    let pageOfItems
    if (!pageIndex && !pageSize) {
      pageOfItems = collections
    } else {
      const startIndex = pageIndex * pageSize
      pageOfItems = collections.slice(
        startIndex,
        Math.min(startIndex + pageSize, collections.length),
      )
    }

    return {
      pageOfItems,
      totalItemCount: collections.length,
    }
  }

  const { pageOfItems, totalItemCount } = findCollections(displayCollections, pageIndex, pageSize)

  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount,
    pageSizeOptions: [25, 50],
    showPerPageOptions,
  }
  const resultsCount =
    pageSize === 0 ? (
      <strong>All</strong>
    ) : (
      <>
        <strong>
          {pageSize * pageIndex + 1}-{Math.min(pageSize * pageIndex + pageSize, totalItemCount)}
        </strong>{' '}
        of {totalItemCount}
      </>
    )
  return (
    <>
      <div className="d-flex justify-content-end">
        <EuiFlexItem grow={false}>
          <EuiFieldText
            placeholder="Filter by name"
            value={sheetTitleFilterInput}
            onChange={(e) => {
              setSheetTitleFilterInput(e.target.value)
            }}
            append={
              <div className="d-flex justify-content-center align-items-center px-3">
                <FaMagnifyingGlass />
              </div>
            }
          />
        </EuiFlexItem>
      </div>

      <EuiSpacer size="xl" />
      <EuiText size="xs">
        Showing {resultsCount} <strong>Collections</strong>
      </EuiText>
      <EuiSpacer size="s" />
      <EuiHorizontalRule margin="none" style={{ height: 2 }} />
      <EuiBasicTable
        tableCaption="Demo for EuiBasicTable with pagination"
        items={pageOfItems}
        columns={columns}
        pagination={pagination}
        onChange={onTableChange}
      />
      <DangerModal
        isVisible={isDeleteModalVisible}
        title="Delete Sheet"
        warningMessage="Are you sure you want to delete this collection?"
        onConfirm={() => {
          if (collectionForDeletion) {
            deleteCollection(collectionForDeletion)
          }
          setIsDeleteModalVisible(false)
        }}
        onCancel={() => {
          setIsDeleteModalVisible(false)
          setCollectionForDeletion(null)
        }}
      />
    </>
  )
}

const createNewCollection = async (title) => {
  const response = await axios.post('/api/user/collections/save', {
    title,
    salesheet_ids: [],
  })

  return response.data
}

const NewCollectionList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [collectionTitle, setCollectionTitle] = useState('')

  const showModal = () => setIsModalVisible(true)
  const closeModal = () => setIsModalVisible(false)

  const handleFormSubmit = async () => {
    const newCollection = await createNewCollection(collectionTitle)

    navigate(`/collection/${newCollection.collection_id}`)
  }

  console.log('modal', isModalVisible)

  useEffect(() => {
    dispatch(fetchAllCollections())
  }, [dispatch])

  return (
    <EuiPageTemplate restrictWidth="90%">
      <div className="container-fluid">
        <div className="row mt-3 px-3">
          <div className="col-7">
            <h2 className="fs-1 fw-bold">Collections</h2>
            <p className="mt-2 mb-3 ps-2">Collections of talent sheets</p>
          </div>
          <div className="col-5 text-end">
            <EuiButton
              onClick={showModal}
              className="text-black"
              color="text"
              style={{ border: '1px dotted darkgrey', maxWidth: '200px' }}
            >
              <CustomIcon Icon={MagicWand} className="me-2 text-black" />
              <span
                style={{
                  textDecoration: 'underline',
                }}
                className="text-link-blue"
              >
                Create New Collection
              </span>
            </EuiButton>
          </div>
        </div>
      </div>
      <EuiPageTemplate.Section>
        <CollectionTable />
      </EuiPageTemplate.Section>
      {isModalVisible && (
        <EuiModal onClose={closeModal}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>Create New Collection</EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            <EuiFieldText
              placeholder="Collection Title"
              value={collectionTitle}
              onChange={(e) => setCollectionTitle(e.target.value)}
            />
          </EuiModalBody>

          <EuiModalFooter>
            <EuiButtonEmpty onClick={closeModal}>Cancel</EuiButtonEmpty>
            <EuiButton onClick={handleFormSubmit} fill>
              Create
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      )}
    </EuiPageTemplate>
  )
}

export default NewCollectionList
