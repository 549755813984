import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import { Bar, Doughnut } from 'react-chartjs-2'

import ChartDataLabels from 'chartjs-plugin-datalabels'
import Color from 'color'
import _ from 'lodash'
import {
  joinClassNames,
  safelyMillify,
  getContrastingTextForBackground,
  getColorHex,
  mixWithBlack,
  mixWithWhite,
} from 'utils'

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)
ChartJS.register(ChartDataLabels)

function DataUnavailable({ title }) {
  return (
    <>
      <h4 className="text-center headline mb-3">Top Countries</h4>
      <h3 className="text-center pt-3">No data available</h3>
    </>
  )
}

function GenderChart({ audience_followers, accentColor, secondaryAccentColor, grayScale = true }) {
  const genderData = _.get(audience_followers, 'audience_gender', [])

  if (_.isEmpty(genderData)) {
    return 'No data available'
  }

  const malePct = Math.round(100 * _.find(genderData, (gd) => gd.code === 'MALE').weight)
  const femalePct = Math.round(100 * _.find(genderData, (gd) => gd.code === 'FEMALE').weight)

  const backgroundColorName = getColorHex(accentColor)

  const contrastingTextColor = getContrastingTextForBackground(backgroundColorName)

  const getContrastingGraphColor = (accentColor) => {
    if (accentColor === 'shine-black') return '#ddd'

    const hexColor = getColorHex(accentColor)

    const hslAccentColor = Color(hexColor).hsl().object()
    const lightness = hslAccentColor.l

    if (lightness > 65) {
      return mixWithBlack(hexColor, 0.2)
    }

    if (lightness < 20) {
      return mixWithWhite(hexColor, 0.7)
    }
    return mixWithWhite(hexColor, 0.5)
  }

  let contrastColor = secondaryAccentColor
    ? getColorHex(secondaryAccentColor)
    : getContrastingGraphColor(accentColor)

  let chartColors = {
    female: Color(backgroundColorName).hex(),
    male: contrastColor,
    borderColorMale: Color(backgroundColorName).darken(0.5).hex(),
    borderColorFemale: Color(backgroundColorName).darken(0.2).hex(),
  }

  if (grayScale) {
    chartColors.male = '#ddd'
    chartColors.female = '#aaa'
    chartColors.borderColorMale = '#1a1a1a'
    chartColors.borderColorFemale = '#1a1a1a'
  }

  let items = [
    {
      label: 'Male',
      data: malePct,
      color: chartColors.male,
      borderColor: chartColors.borderColorMale,
    },
    {
      label: 'Female',
      data: femalePct,
      color: chartColors.female,
      borderColor: chartColors.borderColorFemale,
    },
  ]

  if (malePct <= 50) {
    items = [items[1], items[0]]
  }

  const data = {
    labels: items.map((i) => i.label),
    datasets: [
      {
        label: 'Audience %',
        data: items.map((i) => i.data),
        backgroundColor: items.map((i) => i.color),
        borderColor: items.map((i) => i.borderColor),
        borderWidth: 1,
      },
    ],
  }

  return (
    <div className="gender-chart-container">
      <Doughnut
        data={data}
        options={{
          plugins: {
            legend: {
              display: true,
              position: 'bottom',
            },
            datalabels: {
              color: contrastingTextColor,
            },
          },
        }}
      />
    </div>
  )
}

function AgeChart({ audience_followers, accentColor, grayScale = true }) {
  const ageData = _.get(audience_followers, 'audience_age', []).filter((ad) => {
    return Math.round(ad.weight * 100) > 0
  })

  const options = {
    // responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        display: false,
      },
      datalabels: {
        anchor: 'end',
        align: 'start',
        offset: -18,
        // color: '#36A2EB',
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  }

  const colorHex = getColorHex(accentColor)

  let color = Color(colorHex).rgb().string()

  if (grayScale) {
    color = '#888'
  }

  const data = {
    labels: ageData.map((ad) => ad.code),
    datasets: [
      {
        label: 'Audience %',
        data: ageData.map((ad) => Math.round(ad.weight * 100)),
        backgroundColor: color,
        borderColor: grayScale ? '#1a1a1a' : Color(colorHex).darken(0.5).hex(),
      },
    ],
  }

  return (
    <>
      <Bar options={options} data={data} />
    </>
  )
}

const StatsBox = ({
  infoKey,
  audience_followers,
  advanced_data,
  darkMode = false,
  grayScale = false,
  shadow = 'shadow',
  displaySettings = {},
  className = '',
}) => {
  const accentColor = displaySettings?.displayAccentColor || 'shine-gold'
  const secondaryAccentColor = displaySettings?.displaySecondaryAccentColor

  const ShowRate = ({ n, title }) => {
    if (!n) {
      return null
    }
    let displayNumber = `${Number(n).toFixed(2)}%`
    // if (displayNumber.slice(-2)[0] === '.') {
    //   displayNumber = displayNumber.slice(0, -1)
    // }

    return (
      <>
        <h4 className="text-center headline mb-1">{title}</h4>
        <h3 className="text-center pt-3 fs-3">
          {displayNumber} <span style={{ fontSize: '.55em' }}></span>
        </h3>
      </>
    )
  }

  function ShowNumber({ n, title }) {
    if (!n) {
      return null
    }
    return (
      <>
        <h4 className="text-center headline mb-1">{title}</h4>
        <h3 className="text-center pt-3 fs-3">{safelyMillify(n)}</h3>
      </>
    )
  }

  let headline = ''
  let content

  switch (infoKey) {
    case 'audience_gender':
      headline = 'Audience by Gender'
      content = (
        <>
          <h4 className="text-center headline mb-3">{headline}</h4>
          <div className="d-flex justify-content-center">
            <GenderChart
              audience_followers={audience_followers}
              accentColor={accentColor}
              grayScale={grayScale}
              secondaryAccentColor={secondaryAccentColor}
            />
          </div>
        </>
      )
      break
    case 'audience_age':
      headline = 'Audience by Age'
      content = (
        <div className="d-flex flex-column h-100">
          <h4 className="text-center headline mb-3">{headline}</h4>
          <div className="flex-md-grow-1 align-self-center" style={{ width: '80%' }}>
            <AgeChart
              audience_followers={audience_followers}
              accentColor={accentColor}
              grayScale={grayScale}
            />
          </div>
        </div>
      )
      break
    case 'audience_countries':
      headline = 'Top Countries'
      if (_.isEmpty(_.get(audience_followers, 'audience_geo.countries'))) {
        content = <DataUnavailable title={headline} />
      } else {
        content = (
          <>
            <h4 className="text-center headline mb-3">{headline}</h4>
            <div className="country-list d-flex flex-column justify-content-between">
              {_.get(audience_followers, 'audience_geo.countries', [])
                .slice(0, 5)
                .map((ag, i) => {
                  return (
                    <div className="my-1 pb-1 row" key={`${ag.code}`}>
                      <div
                        className={joinClassNames([
                          `col-2 offset-1 country-rank text-end`,
                          grayScale ? 'text-black' : `text-${accentColor}`,
                        ])}
                      >
                        <div className="d-flex flex-row justify-content-end">
                          <div className="rank-digit text-center">0</div>
                          <div className="rank-digit text-center">{i + 1}</div>
                        </div>
                        {/* {`0${i + 1}`} */}
                      </div>
                      <div className="col-9">
                        <p className="country-name">
                          <span>
                            {/* {getUnicodeFlagIcon(ag.code)}  */}
                            {`${ag.name}: `}
                          </span>

                          {`${Math.round(ag.weight * 100)}%`}
                        </p>
                      </div>
                    </div>
                  )
                })}
            </div>
          </>
        )
      }

      break
    case 'audience_interests':
      headline = 'Audience Interests'

      content = (
        <>
          <h4 className="text-center headline mb-2">{headline}</h4>

          <div className="d-flex flex-row justify-content-center pt-4">
            <p className="w-75 interests">
              {_.get(audience_followers, 'audience_interests', [])
                .slice(0, 7)
                .map((ai) => ai.name)
                .join(', ')}
            </p>
          </div>
        </>
      )
      break
    case 'audience_cities':
      headline = 'Top Cities'
      content = (
        <div className="container">
          {' '}
          <h4 className="text-center headline mb-2">{headline}</h4>
          {_.get(audience_followers, 'audience_geo.cities', [])
            .slice(0, 5)
            .map((ag, i) => {
              return (
                <div key={i} className="row">
                  <div className={`col-8 offset-md-1`}>
                    <p className={'list-name'}>
                      {ag.name}
                      {'  '}
                      {/* {ag.country.code !== 'US' ? `, ${ag.country.name}` : ''}:{' '} */}
                      {`${Math.round(ag.weight * 100)}%`}
                    </p>
                  </div>
                </div>
              )
            })}
        </div>
      )
      break
    case 'avg_views':
      if (!advanced_data || _.isEmpty(safelyMillify(advanced_data.avg_views))) {
        return null
      }
      content = <ShowNumber title={'Average Views'} n={_.get(advanced_data, 'avg_views')} />
      break
    case 'avg_comments':
      if (!advanced_data || _.isEmpty(advanced_data.avg_comments)) {
        return null
      }
      content = <ShowNumber title={'Average Comments'} n={_.get(advanced_data, 'avg_comments')} />
      break
    case 'engagement_rate':
      if (!advanced_data || _.isEmpty(advanced_data.engagement_rate)) {
        return null
      }
      content = <ShowRate title={'Engagement Rate'} n={_.get(advanced_data, 'engagement_rate')} />
      break
    case 'avg_likes':
      if (!advanced_data || _.isEmpty(advanced_data.avg_likes)) {
        return null
      }
      content = <ShowNumber title={'Average Likes'} n={_.get(advanced_data, 'avg_likes')} />
      break

    case 'avg_reels_plays':
      if (!advanced_data || !advanced_data?.avg_reels_plays) {
        return null
      }
      content = (
        <ShowNumber title={'Average Reels Plays'} n={_.get(advanced_data, 'avg_reels_plays')} />
      )
      break
    default:
      return null
  }
  return (
    <div
      className={joinClassNames([
        'very-round-box',
        !darkMode ? 'bg-white' : null,
        shadow,
        'py-3 stats-box',
        className,
      ])}
    >
      {content}
    </div>
  )
}

export default StatsBox
