import { useEffect } from 'react'
import { useSelector } from 'react-redux'

function useSearchOnlyRedirect() {
  const user = useSelector((state) => state.user)

  useEffect(() => {
    if (user.authed && user.search_only && !user.is_admin) {
      window.location.href = '/search'
    }
  }, [user.search_only])
}

export default useSearchOnlyRedirect
