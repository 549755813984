import React, { useState } from 'react'
import { FaRegCopy } from 'react-icons/fa'
import { Tooltip } from 'react-tooltip'

const ClickToCopy = ({ textToCopy, iconId, IconComponent = FaRegCopy, size }) => {
  const [tooltip, setTooltip] = useState(false)

  const handleCopyClick = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(textToCopy).then(() => {
        setTooltip(true)
        setTimeout(() => {
          setTooltip(false)
        }, 1000)
      })
    } else {
      // Fallback for older browsers
      const textArea = document.createElement('textarea')
      textArea.value = textToCopy
      document.body.appendChild(textArea)
      textArea.select()
      try {
        document.execCommand('copy')
        setTooltip(true)
        setTimeout(() => {
          setTooltip(false)
        }, 1000)
      } catch (err) {
        console.error('Unable to copy to clipboard', err)
      }
      document.body.removeChild(textArea)
    }
  }

  return (
    <>
      <span className="m-1">
        <IconComponent
          size={size ? size : '16px'}
          id={iconId}
          className="selectable copy-icon"
          onClick={handleCopyClick}
        />
      </span>
      <Tooltip
        style={{
          fontSize: '14px',
          padding: '6px',
        }}
        anchorSelect={`#${iconId}`}
        content="Link Copied"
        place="right"
        type="dark"
        effect="solid"
        isOpen={tooltip}
      />
    </>
  )
}

export default ClickToCopy
